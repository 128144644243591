<template>
  <div>
    <div v-if="!evenement">
      <v-skeleton-loader
        class="mx-auto"
        max-width="500"
        max-height="500"
        type="card"
      />
    </div>
    <div v-if="evenement">
      <base-material-card
        color="success"
        icon="mdi-calendar"
        inline
        :title="evenement.nom"
        class="px-5 py-3 mb-5"
      >
        <v-img
          class="my-4"
          :src="mediaUrl(evenement.mediaImage)"
          contain
          :aspect-ratio="5/2"
        >
          <div
            v-if="mode == 'ATTENTE'"
            class="compteur my-8"
          >
            {{ $t('conference.ouverture') }} {{ tempsRestant }}
          </div>
        </v-img>
        <div v-if="mode == 'ATTENTE'">
          <div class="text-subtitle-2">
            {{ $t("conference.louverture_des_portes_est") }}
            {{ dateDebut | moment('dddd') }},
            <b>{{ dateDebut | moment('LL') }}</b> {{ $t('general.à') }}
            <b>{{ dateDebut | moment('LT') }}</b>
          </div>
        </div>
        <v-btn
          v-if="mode == 'EN COURS'"
          class="primary"
          data-cy="btnAccederEvenement"
          @click="accéderEvenement(evenement.id)"
        >
          {{ $t('accueil.acceder_a_la_salle_des_presentations') }}
        </v-btn>
        <div
          v-if="mode == 'TERMINÉ'"
          class="text-subtitle-2"
        >
          {{ $t("accueil.l'evenement_est_terminé") }} &#x1F625;
        </div>

        <div
          class="pt-6 overflow-hidden"
          v-html="evenement.description"
        />
      </base-material-card>
    </div>
  </div>
</template>
<style scoped>
.compteur {
  color: white;
  padding-left: 8px;
  font-size: 20px;
  background-color: #00000090;
}
</style>
<script>
  import horlogeService from '@/services/horlogeService.js'
  import moment from 'moment'
  import restApiService from '@/services/restApiService.js'

  export default {
    props: {
      idEvenement: Number,
    },
    data: () => ({
      mode: null,
      dateDebut: null,
      dateFin: null,
      evenement: undefined,
      tempsRestant: '',
    }),
    created() {
      moment.locale(this.$store.state.appLanguage)
    },
    mounted () {
      restApiService.get('/api/evenements/' + this.idEvenement).then(res => {
        this.evenement = res.data
        this.dateDebut = Date.parse(this.evenement.dateDebut)
        this.dateFin = Date.parse(this.evenement.dateFin)
        const now = horlogeService.maintenant()
        if (this.dateDebut > now) {
          this.mode = 'ATTENTE'
        }
        if (this.dateDebut <= now && this.dateFin > now) {
          this.mode = 'EN COURS'
        }
        if (this.dateFin < now) {
          this.mode = 'TERMINÉ'
        }
        setTimeout(this.tic, 1000)
      })
    },
    methods: {
      accéderEvenement (idEvenement) {
        restApiService.get('/api/evenements/' + idEvenement).then(res => {
          this.$store.commit('SET_EVENEMENT_ACTIF', res.data)
          this.$router.push({
            path: `/orka/eve/${idEvenement}/sallepresentations/`,
          })
        })
      },
      mediaUrl (media) {
        return restApiService.mediaUrl(media)
      },

      tic () {
        const now = horlogeService.maintenant()
        this.tempsRestant = moment(this.dateDebut).locale(this.$store.state.appLanguage).from(now)
        setTimeout(this.tic, 1000)
      },
    },
  }
</script>
