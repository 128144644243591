<template>
  <v-container
    id="accueil"
    fluid
    tag="section"
    data-cy="pageSalleAccueil"
  >
    <div
      v-for="infoEvenement in listeInfoEvenements"
      :key="infoEvenement.id"
    >
      <v-row>
        <v-col
          cols="12"
          class="col-sm-8"
        >
          <boite-evenement :id-evenement="infoEvenement.id" />
        </v-col>
        <v-col
          cols="6"
          class="col-sm-4"
        >
          <base-material-card
            color="success"
            icon="mdi-card-account-details"
            inline
            :title="$t('accueil.participant')"
            class="px-5 py-3 mb-5"
          >
            <div class="mt-4">
              <v-icon class="pr-3">
                mdi-account-circle-outline
              </v-icon>
              <span>{{ userInfo.prenom }} {{ userInfo.nom }}</span>
              <!-- <br>
              <v-icon class="pr-3">
                mdi-briefcase
              </v-icon>
              <span>Expérience client</span>
              <br>
              <v-icon class="pr-3">
                mdi-map-marker
              </v-icon>
              <span>Québec</span>
              <br> -->
            </div>
          </base-material-card>
        </v-col>
      </v-row>

      <!-- <base-material-card
        caption="pow"
        color="success"
        icon="mdi-clock-outline"
        inline
        title="Horaire"
        class="px-5 py-3 mb-5"
      >
        <v-data-table
          hide-default-footer
          :headers="headersHoraire"
          :items="infoEvenement.listeConferences"
        >
          <template v-slot:[`item.dateDebut`]="{item}">
            <span>{{ item.dateDebut | moment("LLL") }}</span>
          </template>
          <template v-slot:[`item.dateFin`]="{item}">
            <span>{{ item.dateFin | moment("LT") }}</span>
          </template>
          <template v-slot:[`item.durée`]="{item}">
            <span> {{ durée(item.dateDebut, item.dateFin) }}</span>
          </template>
          <template v-slot:[`item.tempsRestant`]="{item}">
            <span> {{ tempsRestant(item.dateDebut) }}</span>
          </template>
        </v-data-table>
      </base-material-card> -->
    </div>
  </v-container>
</template>

<script>
  // import horlogeService from '@/services/horlogeService.js'
  import restApiService from '@/services/restApiService.js'
  import BoiteEvenement from '../composantes/BoiteEvenement.vue'
  import horlogeService from '@/services/horlogeService.js'
  import moment from 'moment'

  export default {
    name: 'Accueil',
    components: {
      BoiteEvenement,

    },

    data: () => ({
      headersHoraire: [
        {
          text: 'Activité',
          value: 'nom',
        },
        {
          text: 'Débute',
          value: 'dateDebut',
        },
        {
          text: 'Termine',
          value: 'dateFin',
        },
        {
          text: 'Durée',
          value: 'durée',
        },
      ],
      listeInfoEvenements: [],
      userInfo: {},
    }),
    created () {
      this.userInfo = this.$store.state.userInfo
      restApiService
        .get('/api/webUser/listeEvenementsAccessibles')
        .then((result) => {
          // on affiche seulement les évènements avec une date de fin > now
          const now = horlogeService.maintenant()
          this.listeInfoEvenements = result.data.filter(e => {
            return Date.parse(e.dateFin) > now
          })
        })
        .catch((erreur) => alert(erreur))
    },
    methods: {
      durée (dateDebut, dateFin) {
        return moment.utc(moment.duration(moment(dateFin) - moment(dateDebut)).asMilliseconds()).format('HH:mm')
      },
    },
  }
</script>
